"use strict";Object.defineProperty(exports, "__esModule", {value: true});var StateChangeKind = /* @__PURE__ */ ((StateChangeKind2) => {
  StateChangeKind2["ERC20_TRANSFER"] = "ERC20_TRANSFER";
  StateChangeKind2["ERC20_APPROVAL"] = "ERC20_APPROVAL";
  StateChangeKind2["NATIVE_ASSET_TRANSFER"] = "NATIVE_ASSET_TRANSFER";
  StateChangeKind2["ERC721_TRANSFER"] = "ERC721_TRANSFER";
  StateChangeKind2["ERC721_APPROVAL"] = "ERC721_APPROVAL";
  StateChangeKind2["ERC721_APPROVAL_FOR_ALL"] = "ERC721_APPROVAL_FOR_ALL";
  StateChangeKind2["ERC1155_TRANSFER"] = "ERC1155_TRANSFER";
  StateChangeKind2["ERC1155_APPROVAL_FOR_ALL"] = "ERC1155_APPROVAL_FOR_ALL";
  return StateChangeKind2;
})(StateChangeKind || {});
var IndexerUnits = /* @__PURE__ */ ((IndexerUnits2) => {
  IndexerUnits2["DCA"] = "dca";
  IndexerUnits2["AGG_SWAPS"] = "aggSwaps";
  IndexerUnits2["CHAINLINK_REGISTRY"] = "chainlinkRegistry";
  IndexerUnits2["ERC20_APPROVALS"] = "erc20Approvals";
  IndexerUnits2["ERC20_TRANSFERS"] = "erc20Transfers";
  IndexerUnits2["NATIVE_TRANSFERS"] = "nativeTransfers";
  return IndexerUnits2;
})(IndexerUnits || {});



exports.IndexerUnits = IndexerUnits; exports.StateChangeKind = StateChangeKind;
